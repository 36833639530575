import React from "react";
import { Link, navigate } from "gatsby";

import {
  Box,
  Typography,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";

import HeroHome from "../../images/hero-home.png";

import ImgMemory01 from "../../images/memory/memory-01.jpeg";
import ImgMemory02 from "../../images/memory/memory-02.jpeg";
import ImgMemory03 from "../../images/memory/memory-03.jpeg";

import EventPic from "../../images/events/2024-jazz-night.jpeg";

const itemData = [
  {
    img: ImgMemory01,
    title: "Image",
  },
  {
    img: ImgMemory02,
    title: "Image",
  },
  {
    img: ImgMemory03,
    title: "Image",
    rows: 2,
    cols: 2,
  },
];

const Home = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  return (
    <>
      <Box
        component="img"
        src={HeroHome}
        alt="image of racc"
        sx={{ width: `100%` }}
      />
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography variant="h6" align="center">
                August 10, 2024
              </Typography>
              <Typography variant="h4" align="center">
                Jazz by the River
              </Typography>
              <FancyHr center />

              <Box mb={4}>
                <Typography variant="h6" align="center">
                  Pianist: Rhonda York, Singers: Alayna Parvin & Sandy Wood
                </Typography>
                <Typography variant="h6" align="center">
                  Music By: KC Jazz Academy Students
                </Typography>
              </Box>

              <Box
                component="img"
                src={EventPic}
                alt="Jazz by River"
                sx={{ width: `100%` }}
              />
            </Box>
            <Box mb={4}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box mb={4}>
              <Typography variant="h6" align="center">
                Memory of Actor:
              </Typography>
              <Typography variant="h4" align="center">
                Wilbert “Bill” Cobbs
              </Typography>
              <FancyHr center />
              <Typography variant="h6" align="center">
                Death brings heartache and pain that no one can heal, but love
                and memories, no one can steal.
              </Typography>
              <Box mb={4}>
                <Typography variant="h6" align="center">
                  We here at the Richard Allen Cultural Center & Museum or
                  keeping you all in our prayers
                </Typography>
              </Box>
              {/* <Typography align="center">
                <strong>Register Now - $125 / person | $500 / team</strong>
              </Typography> */}
              <ImageList variant="masonry" cols={3} gap={1}>
                {itemData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>

              {/* <Box
                component="img"
                src={EventPic}
                alt="Green Light Vigil"
                sx={{ width: `100%` }}
              /> */}
            </Box>
            <Box mb={4}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#fafafa` }}
      >
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Highlighting African-American history
              </Typography>
            </Box>
            <FancyHr center />

            <Typography paragraph align="center">
              The Richard Allen Cultural Center opened in 1992 to highlight
              African-American history in Leavenworth, Kansas. In 1992, the
              museum opened in the former home of U.S. Army Captain William Bly,
              a Buffalo Soldier during World War I. The home is decorated to
              look as it would have in the early 1900s. In 2002, an addition was
              built to the front of the original home to display more items
              teaching about African-American history in Kansas.
            </Typography>
            <Typography paragraph align="center">
              The center offers a glimpse into the history of African-Americans,
              both local and nationwide. It was created to ensure that the rich
              heritage of African- Americans and their profound effect on
              American society would not be lost.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#EFEFEF` }}
      >
        <Grid container>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Our Mission
              </Typography>
            </Box>
            <FancyHr center />
            <Typography paragraph align="center">
              Our mission and purpose is to educate the public about the rich
              history of African- Americans and their contributions to every
              phase of American life. We promote a greater awareness of
              African-American heritage through research and cultural activities
              that enhance interracial understanding.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} />
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `rgba(37, 40, 58, 1)` }}
      >
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center" color="textSecondary">
                Programs
              </Typography>
            </Box>
            <FancyHr center />
            <Typography paragraph align="center" color="textSecondary">
              Educational programs are also offered through museum exhibits,
              tours, research opportunities and traveling exhibits for the
              general public. We also have a year-round tutoring program
              available for any student who needs the service. We have helped
              over 350 children improve their academic skills.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
